import React from "react";
import InternaScreen from "../components/eventoInterna/InternaScreen";
import Layout from "../components/layout";
import SEO from "../components/seo";

const internaEventoTemplate = ({ pageContext: { resultData } }) => {
  const stripedHtml = resultData.evento?.Descripcion.replace(/<[^>]+>/g, "");

  return (
    <Layout>
      <SEO
        title={resultData.evento.Titulo}
        description={stripedHtml.substr(0, 79)}
        image={resultData.evento.ImagenDestacada}
        pathname={resultData.pathname}
      />
      <InternaScreen resultData={resultData} />
    </Layout>
  );
};

export default internaEventoTemplate;
